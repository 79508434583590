import React, { useState, useEffect } from 'react';

const CookieConsent: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    // Sprawdzamy, czy użytkownik już zaakceptował ciasteczka
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Użytkownik zaakceptował ciasteczka - zapisujemy informację do localStorage
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div style={cookieConsentStyle}>
          <p>
            Ta strona używa ciasteczek, aby zapewnić najlepsze doświadczenie użytkownika. 
            Kliknij "Akceptuję", aby zaakceptować używanie ciasteczek.
          </p>
          <button onClick={handleAccept} style={buttonStyle}>
            Akceptuję
          </button>
        </div>
      )}
    </>
  );
};

const cookieConsentStyle: React.CSSProperties = {
  position: 'fixed',
  bottom: '20px',
  left: '20px',
  right: '20px',
  backgroundColor: '#333',
  color: '#fff',
  padding: '15px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1000,
};

const buttonStyle: React.CSSProperties = {
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default CookieConsent;
