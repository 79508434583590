// componentMap.ts
import Component1 from './main/Component1';
import Component2 from './main/Component2';
import Component3 from './main/Component3';
import Component4 from './main/Component4';
import Component5 from './main/Component5';
import Component6 from './main/Component6';

const componentMap: { [key: string]: React.FC } = {
  Component1,
  Component2,
  Component3,
  Component4,
  Component5,
  Component6,
};

export default componentMap;
