import React, { useState, useEffect, useRef } from 'react';
import styles from './SearchBox.module.css';
import pagesData from '../json/pages.json';

interface Page {
    title: string;
    url: string;
    category: string;
    subcategories?: Page[];
}

interface SearchBoxProps {
    category?: string;
}

const pages: Page[] = pagesData as Page[];

const SearchBox: React.FC<SearchBoxProps> = ({ category }) => {
    const [query, setQuery] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const [isActive, setIsActive] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (query.length > 0) {
            const searchPages = (pages: Page[]): Page | null => {
                for (const page of pages) {
                    const isInCategory = category ? page.category === category : true;

                    // Szukaj tylko w podkategoriach, jeśli jest określona kategoria
                    if (category && isInCategory && page.subcategories) {
                        for (const subcategory of page.subcategories) {
                            if (subcategory.title.toLowerCase().startsWith(query.toLowerCase())) {
                                return subcategory;
                            }
                        }
                    }

                    // Szukaj w głównych kategoriach, jeśli kategoria nie jest określona
                    if (!category && page.title.toLowerCase().startsWith(query.toLowerCase())) {
                        return page;
                    }

                    // Szukaj w podkategoriach, nawet jeśli kategoria nie jest określona
                    if (!category && page.subcategories) {
                        for (const subcategory of page.subcategories) {
                            if (subcategory.title.toLowerCase().startsWith(query.toLowerCase())) {
                                return subcategory;
                            }
                        }
                    }
                }
                return null;
            };

            const firstMatch = searchPages(pages);
            if (firstMatch) {
                const formattedSuggestion = capitalizeFirstLetter(firstMatch.title);
                setSuggestion(formattedSuggestion);
            } else {
                setSuggestion('');
            }
        } else {
            setSuggestion('');
        }
    }, [query, category]);

    useEffect(() => {
        if (spanRef.current && inputRef.current) {
            inputRef.current.style.width = `${spanRef.current.offsetWidth + 10}px`;
        }
    }, [query]);

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab' && suggestion) {
            event.preventDefault();
            setQuery(suggestion);
        }
    };

    const handleFocus = () => {
        setIsActive(true);
    };

    const handleBlur = () => {
        setIsActive(false);
    };

    const capitalizeFirstLetter = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    return (
        <div className={styles.searchContainer}>
            <input
                tabIndex={0}
                type="text"
                value={query}
                onChange={handleInput}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={styles.searchBox}
            />
            <div className={styles.master}>
                <span className={styles.hiddenSpan} ref={spanRef}>
                    {query}
                </span>
                <span>
                    {query.length > 0 && (
                        <span className={styles.suggestion}>
                            {suggestion.startsWith(capitalizeFirstLetter(query)) && query !== suggestion
                                ? suggestion.slice(query.length)
                                : ''}
                        </span>
                    )}
                    {query.length === 0 && (
                        <span className={styles.suggestion}>
                            Search...
                        </span>
                    )}
                    {isActive && <span className={styles.cursor}>_</span>}
                </span>
            </div>
        </div>
    );
};

export default SearchBox;
