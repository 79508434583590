import React from 'react';
import styles from './BorderBox.module.css'; // Importowanie CSS Module

type BorderBoxProps = {
  children?: React.ReactNode;
  className?: string; // Dodatkowe klasy CSS, jeśli chcesz dodać coś więcej
};

const BorderBox: React.FC<BorderBoxProps> = ({ children, className = '' }) => {
  return (
    <div className={`${styles['border-main']} ${className}`}>
      <div className={styles['shadow-inner']}>{children}</div>
      <div className={styles['shadow-drop']}></div>
    </div>
  );
};

export default BorderBox;
