import React from 'react';
import en from '../../languages/en.json';

const Hobby: React.FC = () => (
  <ul>
    <li>{en.hobby["Hobby-1"]}</li>
    <li>{en.hobby["Hobby-2"]}</li>
    <li>{en.hobby["Hobby-3"]}</li>
  </ul>
);

export default Hobby;
