import React, { useEffect, useState } from 'react';
import styles from './NotificationModal.module.css';
import BorderBox from './BorderBox';

interface NotificationModalProps {
  message: string;
  onClose: () => void;
}

const NotificationModal: React.FC<NotificationModalProps> = ({ message, onClose }) => {
  const [isFullyVisible, setIsFullyVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Najpierw pokazuje powiadomienie bez animacji (ustawienie visible)
    setIsVisible(true);

    // Czekaj na pełne pojawienie się powiadomienia, a następnie rozpocznij animację
    const enterTimeout = setTimeout(() => {
      setIsFullyVisible(true);
    }, 10); // Krótkie opóźnienie na ustawienie visible bez animacji

    // Ustaw czas na animację wyjścia po określonym czasie
    const exitTimeout = setTimeout(() => {
      setIsFullyVisible(false); // Uruchamia animację wychodzenia
    }, 1000); // Czas widoczności powiadomienia (1 sekunda)

    return () => {
      clearTimeout(enterTimeout);
      clearTimeout(exitTimeout);
    };
  }, []);

  const handleTransitionEnd = () => {
    if (!isFullyVisible) {
      onClose(); // Wywołaj funkcję zamknięcia po zakończeniu animacji wychodzenia
    }
  };

  return (
    <div
      className={`${styles.modalContainer} ${isFullyVisible ? styles.show : styles.hide}`}
      onTransitionEnd={handleTransitionEnd}
    >
      <BorderBox />
      <div className={styles.blockOwO}>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default NotificationModal;
