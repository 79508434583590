import React from 'react';

const Component1: React.FC = () => {
  return (
    <div>
    
    </div>
  );
};

export default Component1;
