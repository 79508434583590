import React, { useState, useEffect, useRef } from 'react';
import stylesToolTip from './ToolTip.module.css'; // Stylizacje
import language from '../languages/en.json'; // Import pliku JSON bezpośrednio

interface TooltipProps {
  textKey?: string; // Klucz tekstu, który będzie używany do wyciągania treści z JSON (opcjonalny)
  url?: string; // URL strony do podglądu
  position?: 'below' | 'cursor'; // Opcje: 'below' lub 'cursor' (domyślne przy kursorze)
  children?: React.ReactNode; // Zawartość przekazywana do komponentu (opcjonalne)
  isActive?: boolean;
  content?: 'full' | 'partial'; // Typ treści tooltipu, "full" jeśli całość to podgląd strony
}

interface TooltipText {
  [key: string]: string; // Dynamiczne klucze dla tooltipText
}

const Tooltip: React.FC<TooltipProps> = ({ textKey, url, position = 'cursor', children, isActive = false, content = 'partial' }) => {
  const [tooltipText, setTooltipText] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisappearing, setIsDisappearing] = useState(false); // Animacja znikania
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [elementWidth, setElementWidth] = useState(0); // Przechowywanie szerokości elementu
  const triggerRef = useRef<HTMLDivElement>(null); // Referencja do elementu, nad którym pojawia się tooltip
  const timeoutRef = useRef<number | null>(null); // Ref do przechowywania timeouta

  useEffect(() => {
    if (!url && textKey) {
      // Pobieranie tekstu z JSON tylko wtedy, gdy nie mamy `url` do wyświetlania podglądu
      const tooltips: TooltipText = language.tooltipText;
      if (tooltips[textKey]) {
        setTooltipText(tooltips[textKey]);
      } else {
        setTooltipText('Text not found');
      }
    }
  }, [textKey, url]);

  useEffect(() => {
    if (triggerRef.current && position === 'below') {
      // Pobieranie wymiarów i pozycji elementu dla trybu "below"
      const rect = triggerRef.current.getBoundingClientRect();
      setCoords({
        x: rect.left + window.scrollX, // Pozycja X elementu w dokumencie
        y: rect.bottom + window.scrollY, // Pozycja dolnej krawędzi elementu
      });
      setElementWidth(rect.width); // Ustaw szerokość elementu
    }
  }, [isVisible, position]);

  const handleMouseEnter = () => {
    if (!isActive) return; // Tooltip nie pojawi się, jeśli isActive jest false

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Anulowanie poprzedniego timeouta, jeśli istnieje
    }
    setIsVisible(true);
    setIsDisappearing(false); // Resetowanie stanu znikania
  };

  const handleMouseLeave = () => {
    setIsDisappearing(true); // Uruchamiamy animację znikania
    if (position === 'below') {
      timeoutRef.current = window.setTimeout(() => {
        setIsVisible(false); // Po zakończeniu animacji ukrywamy tooltip
        setIsDisappearing(false); // Resetowanie stanu znikania
        timeoutRef.current = null; // Resetowanie timeouta
      }, 250); // Czas trwania animacji (dopasowany do czasu animacji w CSS)
    } else {
      setIsVisible(false);
      setIsDisappearing(false); // Resetowanie stanu znikania
    }
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!isActive) return; // Tooltip nie pojawi się, jeśli isActive jest false

    if (position === 'cursor') {
      setCoords({ x: event.clientX, y: event.clientY });
    }
  };

  return (
    <div
      className={stylesToolTip.tooltipTrigger}
      ref={triggerRef} // Referencja do elementu
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      style={{
        cursor: 'pointer',
        position: content === 'full' ? 'absolute' : 'relative', // Ustawienie pozycji na 'absolute' dla pełnego podglądu
        top: content === 'full' ? "0px" : undefined,
        zIndex: 100,
      }}
    >
      {/* Treść, na którą użytkownik najeżdża (tylko gdy content nie jest "full") */}
      {content !== 'full' && <span className={stylesToolTip.content}>{children}</span>}

      {/* Tooltip wyświetlany na podstawie pozycji */}
      <div
        className={`${stylesToolTip.tooltip} ${position === 'cursor' ? stylesToolTip.tooltipCursor : stylesToolTip.tooltipBelow} ${position === 'below' ? (isDisappearing ? stylesToolTip.disappear : stylesToolTip.appear) : ''}`}
        style={{
          display: isActive && (isVisible || isDisappearing) ? 'block' : 'none', // Ukrywaj tooltip, kiedy isActive jest false lub nie jest widoczny
          top: position === 'cursor' ? coords.y : coords.y, // Ustawienie top dla "cursor" i "below"
          left: position === 'cursor' ? coords.x : coords.x, // Ustawienie left dla "cursor" i "below"
          width: position === 'below' ? `${elementWidth - 16}px` : 'auto', // Szerokość tooltipa dla "below"
          transform: position === 'below' ? 'translateX(0)' : undefined, // Dla "below" nie centrować, użyć pełnej szerokości elementu
          zIndex: 1000
        }}
      >
        {/* Jeśli jest przekazany `url`, iframe ładuje się od razu i jest w DOM, ale ukryty */}
        {url ? (
          <iframe
            src={url}
            title="Website Preview"
            style={{
              width: position === 'below' ? `${elementWidth - 16}px` : '300px',
              height: '200px',
              border: 'none',
              borderRadius: '26px',
              display: isVisible ? 'block' : 'none', // Ukrywanie iframe, gdy tooltip nie jest widoczny
            }}
          />
        ) : (
          tooltipText && <span>{tooltipText}</span>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
