import React, { useState, useEffect, useRef } from 'react';
import styles from './AboutMeAnimation.module.css';

// Importowanie komponentów aplikacji
import Hobby from './AboutMe/Hobby';
import Work from './AboutMe/Work';
import Chatbot from './AboutMe/Chatbot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Tablica aplikacji
const apps = [
  { name: 'Hobby', icon: '🎨' },
  { name: 'Work', icon: '💻' },
  { name: 'Chatbot', icon: '🤖' },
];

// Mapowanie nazw aplikacji na komponenty
const componentMap: { [key: string]: React.FC } = {
  Hobby,
  Work,
  Chatbot,
};

const animations = ['cube', 'flip']; // Pula animacji

const AboutMeAnimation: React.FC = () => {
  const [animationType, setAnimationType] = useState<string>(''); // Zmienna kontrolująca animację
  const [cubeDepth, setCubeDepth] = useState(0); // Głębokość (translateZ) ustawiana dynamicznie
  const containerRef = useRef<HTMLDivElement>(null);
  const [randomAnimation, setrandomAnimation] = useState<string>('');
  const [selectedAppName, setSelectedAppName] = useState<string | null>(null); // Aktualnie wybrana aplikacja

  // Funkcja aktualizująca głębokość na podstawie szerokości kontenera
  const updateCubeDepth = () => {
    const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
    if (randomAnimation === 'cube') {
      setCubeDepth(containerWidth / 2); // Ustawiamy translateZ jako połowę szerokości kontenera
    } else {
      setCubeDepth(0); // Dla innych animacji ustawiamy cubeDepth na 0
    }
  };

  useEffect(() => {
    // Aktualizuj głębokość po załadowaniu komponentu
    updateCubeDepth();

    window.addEventListener('resize', updateCubeDepth);
    return () => window.removeEventListener('resize', updateCubeDepth);
  }, [randomAnimation]); // Zależność od randomAnimation

  const handleAppClick = async (name: string) => {
    if (animationType === '') {
      const temp = animations[Math.floor(Math.random() * animations.length)];
      setrandomAnimation(temp);
      setAnimationType(temp); // Ustaw animację
    }
    setSelectedAppName(name); // Ustaw nazwę wybranej aplikacji
  };

  const handleBackClick = () => {
    setAnimationType(''); // Zresetuj animację po wykonaniu
  };

  // Pobierz komponent na podstawie nazwy wybranej aplikacji
  const SelectedAppComponent = selectedAppName ? componentMap[selectedAppName] : null;

  return (
    <div ref={containerRef} className={styles.container}>
      <div
        className={`${styles.animation} ${styles[animationType]}`}
        style={{ '--cube-depth': `${cubeDepth}px` } as React.CSSProperties}
      >
        {/* Strona z ikonami */}
        <div className={`${styles.face}`}>
          <div className={styles.gridContainer}>
            {apps.map((app, index) => (
              <div
                key={index}
                className={styles.gridItem}
                onClick={() => handleAppClick(app.name)}
              >
                <div className={styles.icon}>{app.icon}</div>
                <div className={styles.label}>{app.name}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Strona z zawartością wybranej aplikacji */}
        <div className={`${styles.face} ${styles[`face_${randomAnimation}`]}`}>
          <div>
            {/* Pasek zakładki z przyciskiem powrotu i nazwą aplikacji */}
            {selectedAppName && (
              <div className={styles.tabBar}>
                <button className={styles.backButton} onClick={handleBackClick}>
                <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <span className={styles.tabTitle}>{selectedAppName}</span>
              </div>
            )}
            <div className={`${styles.faceContent}`}>
            {/* Wyświetlanie zawartości wybranej aplikacji */}
            {SelectedAppComponent ? (
              <SelectedAppComponent />
            ) : (
              <p>Select an app to see its content.</p>
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMeAnimation;
