import React, { useState, useEffect } from 'react';
import BorderBox from './BorderBox';

interface ContentProps {
  items: number; // Liczba elementów do wyświetlenia
  mode?: 'content' | 'dimension'; // Określa tryb: 'content' - zwracanie elementów, 'dimension' - tylko wymiar
}

const Content: React.FC<ContentProps> = ({ items, mode = 'content' }) => {
  const [heightPercentage, setHeightPercentage] = useState(50); // Stan dla wysokości kontenera

  useEffect(() => {
    // Obliczamy liczbę wierszy: dzielimy przez 3, zaokrąglamy w górę, a wynik mnożymy przez 50%
    const rows = Math.ceil(items / 3); // Zakładamy 3 elementy na wiersz
    const newHeightPercentage = rows * 50; // Każdy wiersz dodaje 50% do wysokości
    setHeightPercentage(newHeightPercentage); // Aktualizujemy stan z nową wysokością
  }, [items]);

  // Tryb "dimension" zwraca tylko wymiar
  if (mode === 'dimension') {
    return (
      <div className="dimension-block" style={{ height: `${heightPercentage}%` }}>
      </div>
    );
  }

  // Tryb "content" zwraca elementy
  return (
    <div className="Content Zero" style={{ height: `${heightPercentage}%` }}>
      {Array.from({ length: items }).map((_, index) => (
        <span key={index} tabIndex={0}>
          <BorderBox />
        </span>
      ))}
    </div>
  );
};

export default Content;
