import React, { useEffect, useRef, useState } from 'react';
import './css/Main.css';
import BorderBox from './components/BorderBox';
import SearchBox from './components/SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGamepad, faGears, faHouse, faShop, faUser, faMugHot } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faGithub } from '@fortawesome/free-brands-svg-icons';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Content from './components/GameBox';
import Tooltip from './components/ToolTip'
import DotsPoint from "./components/DotsPoint";
import CookieConsent from './components/CookieConsent';
import NotificationModal from './components/NotificationModal'
import AboutMeAnimation from './components/AboutMeAnimation';
import Cookies from 'js-cookie'; // Importujemy bibliotekę do zarządzania ciasteczkami
import ContentElement from './components/ContentElement';
import mainConfig from './json/main.json';
import Photo from './me.png'

const icons = [
  { icon: faHouse, page: '/' },
  { icon: faUser, page: '/about-me' },
  { icon: faGamepad, page: '/game' },
  { icon: faShop, page: '/shop' },
  { icon: faGears, page: '/settings' },
];

function App() {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [activePage, setActivePage] = useState(window.location.pathname); // Stan przechowujący aktywną stronę

  const [isScrollingLeft, setIsScrollingLeft] = useState(false); // Kontrola, który kontener przewija
  const [isScrollingRight, setIsScrollingRight] = useState(false);
  const [tooltip, setTooltip] = useState<boolean>(true);
  const [cookieConsent, setCookieConsent] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollContentRef = useRef<HTMLDivElement>(null);

  const handleIconClick = (page: string) => {
    setActivePage(page);
  };

  const handleScrollLeft = () => {
    if (!isScrollingRight && scrollContainerRef.current && scrollContentRef.current) {
      setIsScrollingLeft(true);
      scrollContainerRef.current.scrollTop = scrollContentRef.current.scrollTop;
    }
    setIsScrollingLeft(false);
  };

  const handleScrollRight = () => {
    if (!isScrollingLeft && scrollContainerRef.current && scrollContentRef.current) {
      setIsScrollingRight(true);
      scrollContentRef.current.scrollTop = scrollContainerRef.current.scrollTop;
    }
    setIsScrollingRight(false);
  };
  
  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'true') {
      setCookieConsent(true);
    }
  }, []);

  useEffect(() => {
    if (cookieConsent) {
      const savedTooltipState = Cookies.get('tooltipActive');
      if (savedTooltipState) {
        setTooltip(savedTooltipState === 'true');
      }
    }
  }, [cookieConsent]);

  useEffect(() => {
    if (cookieConsent) {
      Cookies.set('tooltipActive', tooltip.toString(), { expires: 999999999 }); // Zapis stanu tooltipu na 7 dni
    }
  }, [tooltip, cookieConsent]);

  const [notification, setNotification] = useState<string | null>(null);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'EMAIL_SENT') {
        setNotification('Email sent successfully!');
      } else if (event.data.type === 'EMAIL_FAILED') {
        setNotification('Failed to send email. Please try again.');
      }
    };

    // Nasłuchiwanie wiadomości z iframe (EmailWidget)
    window.addEventListener('message', handleMessage);

    // Czyszczenie nasłuchiwacza po odmontowaniu komponentu
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleCloseNotification = () => {
    setNotification(null);
  };

  return (
    <Router>
       <CookieConsent />
      {/* Modal wyświetlający powiadomienia */}
      {notification && (
        <NotificationModal message={notification} onClose={handleCloseNotification} />
      )}
      <div className='Menu-Bar'>
        <div className='Bar'>
          <BorderBox>
            <div className='Bar-Inside'>
              {icons.map(({ icon, page }, index) => (
                <Link
                  key={index}
                  to={page}
                  className={activePage === page ? 'active' : ''}
                  onClick={() => handleIconClick(page)}
                  tabIndex={0}
                >
                  <Tooltip textKey={`Menu-Bar-${index}`} isActive={tooltip} position="below"><FontAwesomeIcon icon={icon} /></Tooltip>
                </Link>
              ))}
            </div>
          </BorderBox>
        </div>
      </div>
      <div className='Main'>
        <Routes>
          <Route path="/" element={
            <>
              <header className="Search-Box">
                <BorderBox />
                <SearchBox />
              </header>
              <div className='Content'>
                {mainConfig.elements.map((element, index) => (
                <ContentElement
                  key={index}
                  componentName={element.name}
                  tooltip={element.tooltip}
                  isNew={element.new}
                  index={index}
                />
              ))}
              </div>
            </>
          } />
          <Route path="/about-me" element={
            <div className='Content-Type-2'>
              <div className='Photo-Box focus_' tabIndex={0}>
                <BorderBox />
                <img src={Photo} className='img_me' />
              </div>
              <div className='About-Me-Desc focus_' tabIndex={0}>
                <BorderBox />
                <AboutMeAnimation />
              </div>
              <div className='Skill-Box focus_' tabIndex={0}>
                <BorderBox />
              </div>
              <div className='Social-Container'>
                <span onClick={() => window.open('https://discord.gg/YSp35XMRR8', '_blank')}>
                  <BorderBox />
                  <Tooltip url="https://discord.com/widget?id=1293042874396708926&theme=dark" position="below" isActive={tooltip} content='full'/>
                  <div className='Social-Button' tabIndex={0}>
                    <FontAwesomeIcon icon={faDiscord} />
                    <span className="text">&nbsp;Discord</span>
                  </div>
                </span>
                <span onClick={() => window.open(`${baseUrl}/widget-email`, '_blank')}>
                  <BorderBox />
                  <Tooltip url={`${baseUrl}/widget-email`} position="below" isActive={tooltip} content='full'/>
                  <div className='Social-Button' tabIndex={0}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span className="text">&nbsp;Mail</span>
                  </div>
                </span>
                <span onClick={() => window.open('https://github.com/KruII', '_blank')}>
                  <BorderBox />
                  <div className='Social-Button' tabIndex={0}>
                    <FontAwesomeIcon icon={faGithub} />
                    <span className="text">&nbsp;GitHub</span>
                  </div>
                  
                </span>
                <span onClick={() => window.open('https://buymeacoffee.com/kruii', '_blank')}>
                  <BorderBox />
                  <div className='Social-Button' tabIndex={0}>
                    <FontAwesomeIcon icon={faMugHot} />
                    <span className="text">&nbsp;Tip Jar</span>
                  </div>
                </span>
              </div>
            </div>
          } />
          <Route path="/game" element={
            <>
              <header className="Search-Box">
                <BorderBox />
                <SearchBox category="game" />
              </header>
              <div className='ScrollContainer-Boss' tabIndex={0}>
              <div className='ScrollContainer-Left' ref={scrollContentRef} onScroll={handleScrollLeft}>
                <Content items={12} mode='dimension' />
              </div>
              <div className='ScrollContainer-Right' ref={scrollContainerRef} onScroll={handleScrollRight}>
                <Content items={12} mode='content'/>
              </div>
              </div>
            </>
            } />
          <Route path="/shop" element={
            <>
            <header className="Search-Box">
              <BorderBox />
              <SearchBox category="shop" />
            </header>
            <div className='ScrollContainer-Boss' tabIndex={0}>
            <div className='ScrollContainer-Left' ref={scrollContentRef} onScroll={handleScrollLeft}>
              <Content items={12} mode='dimension' />
            </div>
            <div className='ScrollContainer-Right' ref={scrollContainerRef} onScroll={handleScrollRight}>
              <Content items={12} mode='content'/>
            </div>
            </div>
          </>
          } />
          <Route path="/settings" element={
            <div className='ClearContainer'>
              <BorderBox>
              <DotsPoint text1="ToolTip" checked={tooltip} onCheckChange={setTooltip} />
              </BorderBox>
            </div>
          } />
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
