import React, { useEffect, useRef, useState } from "react";

interface DotsPointProps {
  text1: string;
  checked: boolean;
  onCheckChange: (checked: boolean) => void;
}

const DotsPoint: React.FC<DotsPointProps> = ({ text1, checked, onCheckChange }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [dots, setDots] = useState<string>("");

  useEffect(() => {
    const updateDots = () => {
      if (containerRef.current && textRef.current && checkboxRef.current) {
        const containerWidth = containerRef.current.offsetWidth;

        // Szerokości elementów tekstowych i checkboxa
        const text1Width = textRef.current.clientWidth;
        const checkboxWidth = checkboxRef.current.clientWidth;

        // Uwzględniamy padding: 25px z lewej i prawej strony
        const availableWidth = containerWidth - text1Width - checkboxWidth - (25 * 2);

        // Obliczamy liczbę kropek do wypełnienia
        const dotWidth = 7; // Zakładamy szerokość kropeczki w pikselach
        const numDots = Math.max(0, Math.floor(availableWidth / dotWidth));

        setDots(".".repeat(numDots));
      }
    };

    updateDots();

    window.addEventListener("resize", updateDots);
    return () => window.removeEventListener("resize", updateDots);
  }, []);

  return (
    <div
      ref={containerRef}
      className="dots-point-container"
      style={{
        display: "flex",
        alignItems: "center",
        width: "calc(100% - 25px)",
        padding: "10px 25px",
        boxSizing: "border-box",
      }}
    >
      <span ref={textRef} className="text1">{text1}</span>
      <span
        className="dots"
        style={{
          flexGrow: 1,
          textAlign: "left",
          whiteSpace: "nowrap",
        }}
      >
        {dots}
      </span>
      <input
        type="checkbox"
        ref={checkboxRef}
        className="checkbox"
        checked={checked}
        onChange={(e) => onCheckChange(e.target.checked)}
      />
    </div>
  );
};

export default DotsPoint;
