import React, { useState } from 'react';
import Tooltip from './ToolTip';
import BorderBox from './BorderBox';
import componentMap from './componentMap';
import style from './ContentElement.module.css'

const ContentElement: React.FC<{ componentName: string; tooltip: string; isNew: boolean; index: number }> = ({ componentName, tooltip, isNew, index }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const Component = componentMap[componentName];

  return (
    <span
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      tabIndex={0}
    >
      <BorderBox />
      <div className={style["content"]}>
      {isNew && <div className={style['New-Label']}>New</div>}
      {Component ? <Component /> : <div>Component not found</div>}
      </div>
    </span>
  );
};

export default ContentElement;
